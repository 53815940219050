@use "@angular/material" as mat;
@use "sass:map";
@import "default-theme.scss";
@import "variables.scss";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

font-face {
  font-family: 'DIN-Light';
  src: local("DIN-Light"), url('./assets/fonts/din-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Bold';
  src: url('./assets/fonts/din-bold.woff') format('woff'),
       url('./assets/fonts/din-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'DIN-Light' !important;
  //overflow-x: hidden;
}

ca-header {
  z-index: 1;
}

*:focus {
  outline: 0 !important;
}



/* width */
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 0px;
// }

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4A4A4A;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4A4A4A;
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 1px;
  width: 5px;
}

* {
  -webkit-font-smoothing: antialiased;
}

.mat-typography {
  @extend .cda-typography-body-1;
}

.mat-app-background {
  // background-image: url('./app/assets/background/cda_wallpaper.jpg') !important;
  // background-size: contain;
  // background-color:  mat.get-color-from-palette($cda-primary, 100) !important;
  background-color: $cda-background !important;
}

html,
body {
  height: 100vh;
}

.custom-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px !important;
}

div{
    button.caja-amiga{
      border-radius: 100px !important;
      background-color:#016ab6!important;
      font-family: 'DIN-Light' sans-serif !important;
    }
    button.banca-movil{
      background-color:#1B7882 !important; ;
    }
    button.btn-caja-en-linea{
      background-color: #003473 !important;
      width: 100% !important;
      display: block !important;
    }
  }

  
  
