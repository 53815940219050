@use "sass:map";

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Regular.ttf") format("truetype");
}

$font-family: "Poppins";
$font-color: #4A4A4A;

$font-scale: (
  headline-1: 6rem,
  headline-2: 3.75rem,
  headline-3: 3rem,
  headline-4: 2.125rem,
  headline-5: 1.5rem,
  headline-6: 1.25rem,
  headline-7: 1rem,
  subtitle-1: 1rem,
  subtitle-2: 0.875rem,
  body-1: 1rem,
  body-2: 0.875rem,
  button: 0.875rem,
  caption: 0.75rem,
  overline: 0.75rem,
);

@mixin typography($type, $weight, $font-color) {
  font-family: $font-family !important;
  font-size: map-get($font-scale, $type) !important;
  font-weight: $weight !important;
  color: $font-color ;
  line-height: 1.6;
  letter-spacing: normal;
}

.cda-typography-h1 {
  @include typography("headline-1", 300, $font-color);
}

.cda-typography-h1-bold {
  @include typography("headline-1", "bold",$font-color);
}

.cda-typography-h2 {
  @include typography("headline-2", 300, $font-color);
}

.cda-typography-h2-bold {
  @include typography("headline-2", 300, $font-color);
}

.cda-typography-h3 {
  @include typography("headline-3", 400, $font-color);
}

.cda-typography-h3-bold {
  @include typography("headline-3", bold, $font-color);
}

.cda-typography-h4 {
  @include typography("headline-4", 400, $font-color);
}

.cda-typography-h4-bold {
  @include typography("headline-4", bold, $font-color);
}

.cda-typography-h5 {
  @include typography("headline-5", 400, $font-color);
}

.cda-typography-h5-bold {
  @include typography("headline-5", bold, $font-color);
}

.cda-typography-h6 {
  @include typography("headline-6", 500, $font-color);
}

.cda-typography-h6-bold {
  @include typography("headline-6", bold, $font-color);
}

.cda-typography-h7 {
  @include typography("headline-7", 500, $font-color);
}

.cda-typography-h7-bold {
  @include typography("headline-7", bold, $font-color);
}

.cda-typography-subtitle-1 {
  @include typography("subtitle-1", 700, $font-color);
}

.cda-typography-subtitle-2 {
  @include typography("subtitle-2", 400, $font-color);
}

.cda-typography-body-1 {
  @include typography("body-1", 400, $font-color);
}

.cda-typography-body-2 {
  @include typography("body-2", 400, $font-color);
}

.cda-typography-button {
  @include typography("button", 500, #fff);
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.cda-typography-caption {
  @include typography("caption", 400, $font-color);
}

.cda-typography-overline {
  @include typography("overline", 400, $font-color);
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
